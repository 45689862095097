import React, { useState, useEffect } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import styles from './map.module.css';

const MapComponent = ({ lat, lng, zoom }) => {
  const [viewport, setViewport] = useState({
    width: '100%',
    height: 605,
    latitude: lat,
    longitude: lng,
    zoom: zoom,
  });

  const _onViewportChange = (viewport) =>
    setViewport({ ...viewport, transitionDuration: 100, width: '100%' });

  useEffect(() => {
    setViewport({
      ...viewport,
      latitude: lat,
      longitude: lng,
      zoom: zoom,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lat, lng, zoom]);

  return (
    <div>
      <ReactMapGL
        {...viewport}
        mapboxApiAccessToken={process.env.MAPBOX_API_TOKEN}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onViewportChange={_onViewportChange}
      >
        <Marker latitude={lat} longitude={lng} offsetLeft={-20} offsetTop={-10}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="map-marker-alt"
            className={`svg-inline--fa fa-map-marker-alt fa-w-12 ${styles.marker}`}
            role="img"
            viewBox="0 0 384 512"
          >
            <path
              fill="currentColor"
              d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z"
            />
          </svg>
        </Marker>
      </ReactMapGL>{' '}
    </div>
  );
};

export default MapComponent;
