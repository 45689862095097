import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Helmet from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import MapComponent from '../components/map/mapComponent';
import ContactsCard from '../components/contacts-card/contactsCard';

const Contacts = (props) => {
  const contactsInfo = props.data.allContentfulContacts.edges;

  return (
    <Layout location={props.location}>
      <Helmet>
        <title>Contacts - {props.data.site.siteMetadata.title}</title>
        <meta name="description" content={props.data.site.siteMetadata.description} />
      </Helmet>
      <h2 className="section-headline">Contacts</h2>
      {contactsInfo.map(({ node }) => {
        return (
          <Row key={node.id}>
            <Col lg={8} key={node.id}>
              <MapComponent lat={node.location.lat} lng={node.location.lon} zoom={12} />
            </Col>
            <Col>
              <ContactsCard
                name={node.name}
                phone={node.phone}
                address={node.address}
                email={node.email}
                instagram={node.instagram}
                linkedin={node.linkedIn}
                facebook={node.facebook}
                github={node.gitHub}
                fluidPhoto={node.photo.fluid}
              />
            </Col>
          </Row>
        );
      })}
    </Layout>
  );
};

export default Contacts;

export const ContactsQuery = graphql`
  query ContactsQuery {
    allContentfulContacts {
      edges {
        node {
          id
          location {
            lat
            lon
          }
          phone
          address
          name
          email
          linkedIn
          instagram
          facebook
          gitHub
          photo {
            fluid(maxWidth: 400) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
