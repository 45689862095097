import React from 'react';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';
import Img from 'gatsby-image';
import styles from './contactsCard.module.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fab } from '@fortawesome/free-brands-svg-icons';

const ContactsCard = ({
  name,
  address,
  phone,
  email,
  fluidPhoto,
  linkedin,
  github,
  facebook,
  instagram,
}) => {
  library.add(fab);

  return (
    <Accordion defaultActiveKey="0">
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            {name}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <Img alt={name} fluid={fluidPhoto} className={styles.addressPhoto} />
            <div>
              <span>Address: </span>
              {address}
            </div>
            <div>
              <span>Phone: </span>
              <a href={`telto:${phone}`}>{phone}</a>
            </div>
            <div>
              <span>Email: </span>
              <a href={`mailto:${email}`}>{email}</a>
            </div>
            <a target="_blank" rel="noreferrer" href={linkedin}>
              <FontAwesomeIcon className={styles.icon} icon={['fab', 'linkedin']} />
            </a>
            <a target="_blank" rel="noreferrer" href={github}>
              <FontAwesomeIcon className={styles.icon} icon={['fab', 'github']} />
            </a>
            <a target="_blank" rel="noreferrer" href={instagram}>
              <FontAwesomeIcon className={styles.icon} icon={['fab', 'instagram']} />
            </a>
            <a target="_blank" rel="noreferrer" href={facebook}>
              <FontAwesomeIcon className={styles.icon} icon={['fab', 'facebook']} />
            </a>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default ContactsCard;
